/** @jsx h */
import { h } from "preact";
import { useState, useEffect, useContext } from "preact/hooks";
import { useStyles } from "style/style";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../components/atoms/cubeButton/CubeBtn";
import { UserInfo as UserInfoBlock } from "../../components/molecules/userInfo/UserInfo"
import { NextVisitList } from "components/molecules/nextVisitList/NextVisitList";
import Modal from "../../components/atoms/modal/Modal";
import EmployeeInfo from "../../components/organisms/modalContent/employee/EmployeeInfo";
import moment from "moment";
import I18n from "i18n/i18n";
import Context from "../../context";

import { getClient, getClientBranch, putUserUpdateID, getUser, postImage } from "api"

import { useRole, useUserImage } from "hooks";

// context snackbar error handling
import { errHandler } from "../../util";
import { route } from "preact-router";

const ToolBar = ({ data, back, doEdit, isOwnProfile = false }) => {
    const role = useRole();

    return (
        <div className="d-flex jc-space-btw">
            <div className="d-flex al-center">
                <Button iconType="back" onClick={back} />
                <div style={{ width: '24px' }} />
                <Typography variant="h5" color="inherit">
                    {data}{I18n.t("UserProfile")}
                </Typography>
            </div>
            <div className="d-flex">
                { 
                    (role.isBigBoss || isOwnProfile) && 
                    <Button iconType="edit" text="Edit" onClick={doEdit} />
                }
            </div>
        </div>
    )
}

const DisableModal = ({ open, handleClose, name, submit = () => {} }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle style={{ color: "rgba(0, 0, 0, 0.58)" }}>
                <b>{I18n.t("DisableConfirmTitle")}</b>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                {/* currently the modal only disable account, make it optional render if needed */}
                {I18n.t("DisableConfirmAccount")}

                {I18n.t("DisableConfirm")}
                <span style={{ color: "rgba(0, 0, 0, 0.87)" }}>{name}</span>
                {I18n.t("Questionmark")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button text={I18n.t("Cancel")} textColor="rgba(0, 0, 0, 0.26)" onClick={handleClose} />
                <Button text={I18n.t("Disable")} textColor="red" onClick={submit} />
            </DialogActions>
        </Dialog>
    );
};

const UserProfile = ({ data, back, handleDisable, reloadUserInfo }) => {
    const classes = useStyles();
    const { getUserImage, removeUserImage } = useUserImage()
    const [modal, setModal] = useState(false);
    const { 
        user: selfUser,
        roleList, 
        setError,
    } = useContext(Context)
    const [loading, setLoading] = useState(false);
    const [selectData, setSelectData] = useState(data);
    const [refresh, setRefresh] = useState(false);

    const editEmployee = async (val) => {
        setLoading(true);
        try {
            const {
                emailAddress,
                employeeRoles,
                firstname,
                lastname,
                phoneNumber,
                workingTeam,
                team,
                tempAvatar,
                address,
                city,
                zipCode
                // division,
                // id_erp,
                // erp_symbol
            } = val;
            const { id } = selectData;
    
            if (!!tempAvatar) {
                const formData = new FormData();
                formData.append('image', tempAvatar);
                await postImage('user', id, formData)
                removeUserImage(id)
            }

            await putUserUpdateID(id, {
                email: emailAddress,
                phone: phoneNumber,
                first_name: firstname,
                last_name: lastname,
                id_group: team,
                id_role: employeeRoles + 1,
                id_region: workingTeam,
                address: address || undefined,
                zip_code: zipCode || undefined,
                city: city || undefined,
                // division,
                // id_erp,
                // erp_symbol
            });

            setModal(false);
            // reload userData
            setUserData(null);
            // reload selectData by updating userInfo from User.js
            reloadUserInfo(id);
        } catch (err) {
            errHandler(err, setError, route);
        } finally {
            setLoading(false);
        }
    }

    /** USER PROFILE API */
    // local state hook for user profile
    const [userData, setUserData] = useState(null);

    const computeUserData = async () => {
        if (!selectData) return;
        try {
            const res = await getUser({ id: selectData.id, include_obselete: true });
            const { data } = res;
            const {
                first_name,
                last_name,
                phone,
                email,
                address,
                id_role
            } = data;
            const joined_date = moment(data.created_at).utc().format("YYYY/MM/DD");
            const experience = moment(data.created_at).fromNow(true)
            let roleStr = roleList[id_role - 1].name;

            let userImageUrl = await getUserImage(selectData.id)
            // update UI with fetched result
            setSelectData(prev=>({ ...prev, imgUrl: userImageUrl }))
            setUserData(prev=>({
                ...prev, 
                ...{
                    main: {
                        name: `${last_name}${first_name}`,
                        image: userImageUrl,
                        transportType: '',
                    },
                    list: [
                        {
                            title: "FirstName",
                            value: first_name
                        },
                        {
                            title: "LastName",
                            value: last_name,
                        },
                        {
                            title: "email",
                            value: email
                        },
                        {
                            title: "phone",
                            value: phone ? `+886${Array.from(phone).map((c, i) => i % 3 == 0 ? ` ${c}` : c).join("")}` : "",
                        },
                        {
                            title: "Address",
                            value: address
                        },
                        {
                            title: 'JoinedDate',
                            value: joined_date,
                        },
                        {
                            title: 'Experience',
                            value: experience
                        }
                    ],
                    fetched: true,
                    roleStr,
                }
            }))
        } catch (err) {
            errHandler(err, setError, route);
        }
    }

    useEffect(() => {
        if (data) {
            setSelectData(data);
            setRefresh(prev => !prev);
        }
    }, [data]);

    // fetch from API
    useEffect(() => {
        computeUserData();
    }, [modal, selectData.id, refresh]);

    return (
        <div className={classes.root}>
            <ToolBar
                data={selectData.last_name + selectData.first_name}
                back={back}
                doEdit={() => setModal("edit")}
                isOwnProfile={selectData.id === selfUser.id}
            />
            <div className={`${classes.marginTop2} d-flex`}>
                <UserInfoBlock
                    title="Information"
                    style={{ width: '100%' }}
                    data={userData}
                    isOwnProfile={selectData.id === selfUser.id}
                    handleDisable={() => setModal('disable')}
                />
                {
                    userData?.roleStr === "ROLE_SALES" ?
                        <div className={`${clsx(classes.paddingLeft1, classes.marginLeft2)} w-50 column`}>
                            <NextVisitList
                                type='default'
                                loading={loading}
                                setLoading={setLoading}
                                filterOptions={{
                                    // currently the add function use this as the adding target
                                    id_user: selectData.id,
                                }}
                                // modify how the listData should be
                                renderList={async (d) => {
                                    // (d) = data[i] from getSchedule api
                                    if (moment().isSameOrBefore(d.startTime, 'second')) {
                                        const clientBranch = (await getClientBranch({ id: d.clientBranchID })).data[0]
                                        const clientGroup = (await getClient({ id: clientBranch.id_client })).data[0]
                                        const timeRangeString
                                            = `${moment(d.startTime).format("HH:mm")} - ${moment(d.endTime).format("HH:mm")}`;
                                        return {
                                            id: d.id,
                                            // add this to specify icon of listitem
                                            icon: clientBranch.name,
                                            group: clientGroup.name,
                                            branch: clientBranch.name,
                                            note: d.remarks,
                                            time: [moment(d.startTime).format("YYYY/MM/DD"), timeRangeString],
                                            ...d
                                        }
                                    }
                                }}
                            />
                        </div> :
                        null
                }
            </div>
            <Modal
                title={["EditAn", "Employee"]}
                open={modal === "edit"}
                close={() => {
                    setLoading(false);
                    setModal(false);
                }}
                style={{ paddingBottom: '64px' }}>
                <EmployeeInfo
                    label="edit"
                    onClick={editEmployee}
                    loading={loading}
                    userData={selectData}
                />
            </Modal>
            <DisableModal
                open={modal == "disable"}
                handleClose={() => setModal(false)}
                submit={() => handleDisable(selectData.id)}
            />
        </div>
    )
}
export default UserProfile;
